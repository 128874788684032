<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="searchStatus" status="my-orange" @click="searchStatus = false">隐藏查询</vxe-button>
        <vxe-button v-if="!searchStatus" status="my-orange" icon="fa fa-search" @click="searchStatus = true">查询</vxe-button>
        <vxe-button v-permission="'NewsNewsAddButton'" status="my-orange" icon="fa fa-plus" @click="handleAdd()">添加</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="searchStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)" @reset="handleSearch(true, true)">
        <vxe-form-item title="分类" span="6">
          <vxe-select v-model="listQuery.param.category" placeholder="请选择分类" clearable @change="$refs.subCategory.clearEvent()">
            <vxe-option
              v-for="(item, index) in dict.category"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item v-show="listQuery.param.category" title="子分类" span="6">
          <vxe-select ref="subCategory" v-model="listQuery.param.subCategory" placeholder="请选择子分类" clearable>
            <vxe-option
              v-for="(item, index) in dict[listQuery.param.category]"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item title="标题" span="6">
          <vxe-input v-model="listQuery.param.newTile" placeholder="请输入标题" clearable />
        </vxe-form-item>
        <vxe-form-item title="状态" span="6">
          <vxe-select v-model="listQuery.param.shows" placeholder="请选择状态" clearable>
            <vxe-option
              v-for="(item, index) in showsArray"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <CustomPic slot="photo" slot-scope="{row}" :src="row.photo || ''" :src-list="[row.photo]" />
      <template slot="operating" slot-scope="{row}">
        <el-button v-permission="'NewsNewsCheckButton'" size="small" type="text" @click="handleDetail(row.id)">查看</el-button>
        <el-button v-permission="'NewsNewsEditButton'" size="small" type="text" @click="handleEdit(row.id)">编辑</el-button>
      </template>
    </CustomList>
    <Form ref="Form" @submit="handleSearch()" />
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
import Form from './components/form'
export default {
  name: 'News',
  components: { Form, CustomList, ...CustomTemplate },
  data() {
    return {
      searchStatus: false,
      dict: {
        category: {
          partyNewsType: '党群红盟新闻',
          volunteerNewsType: '志愿者新闻',
          newsType: '新闻公告'
        },
        partyNewsType: {},
        volunteerNewsType: {},
        newsType: {}
      },
      showsArray: ['隐藏', '显示'],
      listQuery: {
        param: {
          hasTenant: true
        }
      },
      tableColumns: [
        {
          prop: 'categoryName',
          title: '分类',
          minWidth: 150
        },
        {
          prop: 'subCategoryName',
          title: '子分类',
          minWidth: 150
        },
        {
          prop: 'newTile',
          title: '标题',
          minWidth: 200
        },
        {
          title: '图片',
          slot: 'photo'
        },
        {
          title: '状态',
          formatter: (row, column) => {
            return row.shows in this.showsArray ? this.showsArray[row.shows] : ''
          },
          minWidth: 150
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating',
          minWidth: 150
        }
      ]
    }
  },
  created() {
    Object.keys(this.dict.category).forEach(key => {
      this.getSysDictList(key)
    })
  },
  methods: {
    ...mapActions(['getSysDictServe', 'newsPublicPageList']),
    getSysDictList(typeCode) {
      this.getSysDictServe({ typeCode }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        res.data.forEach(res => {
          this.dict[typeCode][res.dictPidVal] = res.dictDesc
        })
      })
    },
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.customListGetList(resetCurrentPage)
    },
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      this.newsPublicPageList(params).then(response => {
        callback(response)
      }).catch(() => {})
    },
    handleAdd() {
      this.$refs.Form.handleAdd()
    },
    handleDetail(id) {
      this.$refs.Form.handleDetail(id)
    },
    handleEdit(id) {
      this.$refs.Form.handleEdit(id)
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
